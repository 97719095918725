<template>
  <v-alert
    v-model="deliveryAlert"
    dismissible
    color="cyan"
    border="left"
    elevation="2"
    colored-border
    icon="mdi-school"
  >
    <div v-html="$t('components.products.delivery_alert', { date: deliveryDate })"></div>
  </v-alert>
</template>

<script>
import { estimated_delivery_date } from '../constants/miscellaneous'

export default {
  name: 'etdBanner',
  data() {
    return {
      deliveryAlert: true,
      estimated_delivery_date
    }
  },
  computed: {
    deliveryDate() {
      return this.$store.getters.estimatedDeliveryDate;
      /*var date = new Date();
      var delivery_date = date.setDate(date.getDate() + this.estimated_delivery_date);
      return this.formatDate(delivery_date);*/
    }
  }
}
</script>
